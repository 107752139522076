import sillaPhiniImg from '../assets/img/mobiliario/silla-phini.webp'
import sillonNuvolaImg from '../assets/img/mobiliario/sillon-nuvola.webp'
import mesaPhiniImg from '../assets/img/mobiliario/mesa-phini.webp'
import biomboImg from '../assets/img/mobiliario/biombo-alvar.webp'
import candelabroEtnaImg from '../assets/img/mobiliario/candelabro-etna.webp'
import domingaImg from '../assets/img/mobiliario/mesa-dominga.webp'
import cuadroImg from '../assets/img/mobiliario/cuadro.webp'
import mesaSatuImg from '../assets/img/mobiliario/mesa-satu.webp'
import sillaOdaImg from '../assets/img/mobiliario/silla-oda.webp'
import varillasImantadasImg from '../assets/img/mobiliario/varillas-imantadas.webp'
import floreroImg from '../assets/img/mobiliario/florero-etna.webp'
import floreroOdoImg from '../assets/img/mobiliario/florero-odo.webp'
import sillonNinoImg from '../assets/img/mobiliario/sillon-nino.webp'
import mesaLoopImg from '../assets/img/mobiliario/mesa-loop.webp'
import percheroImg from '../assets/img/mobiliario/perchero-tor.webp'
import repisaNesImg from '../assets/img/mobiliario/repisa-nes.webp'
import repisaPortoImg from '../assets/img/mobiliario/repisa-porto.webp'
import bandejaImg from '../assets/img/mobiliario/bandeja-tabo.webp'


export default [
    {
        nombre:'Silla Phini',
        slug: 'silla-phini',
        img: sillaPhiniImg,
        desc: 'La Silla Phini es el resultado de su propia lógica constructiva. La constituyen tres piezas: asiento y respaldo en roble macizo y la incorporación de un parante que define la inclinación apropiada para la comodidad del usuario. Una propuesta sintetica, de fácil lectura la vuelve honesta y concreta. Esa es la belleza de esta silla.'
    },
    {
        nombre:'Biombo Alvar',
        slug: 'biombo-alvar',
        img: biomboImg,
        desc: "El Biombo Alvar es reproducido por ODA como tributo a uno de los mas afamados arquitectos del siglo XX'."
    },
    {
        nombre:'Mesa Dominga',
        slug: 'mesa-dominga',
        img:domingaImg,
        desc: "La Mesa Dominga,  fue  pensada  como un objeto con capacidad para  adaptarse con facilidad  a diversas funciones."
    },
    {
        nombre:'Mesa Phini',
        slug: 'mesa-phini',
        img: mesaPhiniImg,
        desc: "La fuerte entidad de la Mesa Phini, otorgada por la madera de roble macizo que la compone, acompanada por el caracter disruptivo de su figura, ocupa un lugar protagonista en los espacios de reunion brindandoles una impronta calida y relajada."
    },
    {
        nombre:'Cuadro de Papel Artesanal',
        slug: 'cuadro-de-papel-artesanal',
        img: cuadroImg,
        desc: "Los papeles utilizados por ODA son realizados artesanalmente. Provienen de las fibras naturales obtenidas del árbol tabaquillo (color marrón), el quebracho colorado (color bordeau) y la paja brava (color natural). También deriva de la utilización de cebollas y uvas, entre otros. El producto se obtiene luego de una investigación de los distintos vegetales. Cada planta ofrece un papel diferente en su color, textura y propiedades. Cada resultado es único."
    },
    {
        nombre:'Florero Etna',
        slug: 'florero Etna',
        img: floreroImg,
        desc: "Objeto de producción artesanal, confeccionados con pasta de gres pigmentada con óxidos minerales naturale."
    },
    {
        nombre:'Florero ODO',
        slug: 'florero-odo',
        img: floreroOdoImg,
        desc: "Objeto de producción artesanal, confeccionado mediante el soplado delicado e individualizado del vidrio. Cada unidad constituye una pieza única e irrepetible."
    },
    {
        nombre:'Sillón Nino',
        slug: 'sillon-nino',
        img: sillonNinoImg,
        desc: "Sofa diseñado para un ambiente distendido, atemporal, generador de encuentros. Posee cómodos asientos y confortables almohadones que facilitan la pausa. La estructura es de madera, el relleno de espuma de poliuretano y guata de poliester. Los almohadones están confeccionados con pluma y espuma de poliuretano revestida con tela de algodón. El tapizado es realizado con genero a elección, como también su funda, elemento opcional que lo reviste."
    },
    {
        nombre:'Mesa Loop',
        slug: 'mesa-loop',
        img: mesaLoopImg,
        desc: "La Mesa Loop representa el punto de encuentro entre la sutileza refinada de las formas redondas, la funcionalidad del objeto y la solidez  de la pieza. La conjunción de estos elementos permite que la mesa principal constituya el lugar intimo de reunión para compartir espacio con la familia o amigos."
    },
    {
        nombre:'Perchero Tor',
        slug: 'perchero-tor',
        img: percheroImg,
        desc: "El Perchero Tor y su par el Espejo Tor, elaborados en  roble macizo, poseen  una sólida apariencia que les permite ignorar las reglas rígidas de uso.  Constituyen una pieza ideal para espacios altos."
    },
    {
        nombre:'Repisa Nes',
        slug: 'repisa-nes',
        img: repisaNesImg,
        desc: "La repisa Nes es una solución de almacenamiento diseñada para adaptarse a cualquier espacio."
    },
    {
        nombre:'Repisa Porto',
        slug: 'repisa-porto',
        img: repisaPortoImg,
        desc: "El diseno de la Repisa Porto muestra con franqueza  la estructura  de un objeto, se encuentran a la vista en forma clara los elementos que la integran."
    },
    {
        nombre:'Mesa Satu',
        slug: 'mesa-satu',
        img: mesaSatuImg,
        desc: "La mesa Satu elaborada con  dos piezas encastradas de madera de roble macizo, posee el encanto de la nitidez de los objetos. La transparencia del vidrio realza la integridad del diseno."
    },
    {
        nombre:'Silla ODA',
        slug: 'silla-oda',
        img: sillaOdaImg,
        desc: "La Silla ODA disenada con una silueta geometrica, es un asiento que resalta lo simple y puro, ello la vuelve apta para cualquier ambiente."
    },
    {
        nombre:'Varillas Imantadas',
        slug: 'varillas-imantadas',
        img: varillasImantadasImg,
        desc: "Las Varillas Imantadas son sujetadores de madera. Constituyen un objeto utilitario que realza la exposicion de los papeles artesanales."
    },
    {
        nombre:'Bandeja Tabo',
        slug: 'bandeja-tabo',
        img: bandejaImg,
        desc: "La Bandeja Tabo, dotada de una estudiada simplicidad, fue pensada para acompañar tus historias cotidianas."
    },
    {
        nombre:'Candelabro Etna',
        slug: 'candelabro-etna',
        img: candelabroEtnaImg,
        desc: "Objetos de producción artesanal, confeccionados con pasta de gres pigmentada con óxidos minerales naturales."
    },
]